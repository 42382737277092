import React from 'react';
import { bool, func, string } from 'prop-types';
import i18next from 'i18next';

import LoadingWrapper from '../LoadingWrapper';
import Button from '../Button';

import ChoiceCard from './components/ChoiceCard';
import styles from './styles.module.scss';
import { CHOICE_CARDS, HEADERS, MANUALLY } from './constants';
import GuarantorInformation from './components/GuarantorInformation';

function FlowChoice({ incumbent, Header, handleUniqueKeyChoice, handleManualChoice, loading }) {
  return (
    <LoadingWrapper loading={loading}>
      <div className={styles.container}>
        <div className={styles.infoContainer}>
          {HEADERS(Header).map(({ id, Component, containerClassName, ...params }) => (
            <div key={id} className={containerClassName}>
              <Component {...params} />
            </div>
          ))}
        </div>
        <div className={styles.buttonsContainer}>
          <GuarantorInformation incumbent={incumbent} />
          {CHOICE_CARDS(handleUniqueKeyChoice, handleManualChoice).map(
            ({ id, subtitle, onClick, ...props }) =>
              id === MANUALLY ? (
                <Button className={styles.manualButton} onClick={onClick} underline lowercase info>
                  {i18next.t('FlowChoice:complete')} {subtitle}
                </Button>
              ) : (
                <ChoiceCard key={id} subtitle={subtitle} onClick={onClick} {...props} />
              )
          )}
        </div>
      </div>
    </LoadingWrapper>
  );
}

FlowChoice.propTypes = {
  handleManualChoice: func,
  handleUniqueKeyChoice: func,
  Header: func,
  incumbent: string,
  loading: bool
};

export default FlowChoice;
